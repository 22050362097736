import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import dynamic from "next/dynamic";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { MouseEventHandler, ReactNode } from "react";
import { CONTRACT_ADDRESS, OS_SLUG, WEBSITE_URL } from "../constants/env";
import EtherscanIcon from "../public/etherscan-light.svg";
import OpenseaIcon from "../public/opensea.svg";

const MintBox = dynamic(() => import("../components/MintBox"), { ssr: false });

export default function Home() {
  return (
    <>
      <Head>
        <title>frankensteintown.wtf</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="NFT, Free, Ethereum" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="min-h-screen w-full grid grid-cols-1 grid-rows-1">
        <div className="bkg w-full relative grid grid-cols-1 grid-rows-1 row-start-1 col-start-1 select-none -z-10">
          <img className="h-full w-full row-start-1 col-start-1 object-contain -z-10" src="/banner.png" alt="" />
        </div>

        <nav className="absolute top-0 w-full flex flex-row justify-end items-center p-4 gap-3">
          {[
            [`https://etherscan.io/address/${CONTRACT_ADDRESS}`, "Etherscan", <EtherscanIcon key={0} />],
            [`https://opensea.io/collection/${OS_SLUG}`, "Opensea", <OpenseaIcon key={1} />],
            ["https://twitter.com/frankensteinwth", "Twitter", <FontAwesomeIcon icon={faTwitter} color="white" key={2} />],
          ].map(([href, title, icon], idx) => (
            <a
              key={idx}
              className="grid place-items-stretch w-12 h-12 p-2 border-2 rounded-lg hover:animate-shaking"
              href={href as string}
              target="_blank"
              rel="noreferrer"
            >
              {icon}
            </a>
          ))}
        </nav>

        <main className="row-start-1 col-start-1 flex flex-col items-start">
          <h1 className="my-12 px-24 w-full text-8xl text-cyan-700 text-left font-bold select-none leading-none" style={{ textShadow: "1px 3px 0px cyan" }}>
            FrankenSteinTown
          </h1>
          <MintBox />
        </main>
      </div>
    </>
  );
}
